import Axios from "axios" 

import { apiUrl } from "../Vars";

const AddInvoice = (id) => {

  console.log(id)

  Axios.post(`${apiUrl}/api/admin/add-invoice`, {
    orderId: id
  }, {
    headers: {
      "x-access-token" : `${sessionStorage.getItem("token")}`
    }, 
    withCredentials: true
  })
    .then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
    })
}

export { AddInvoice }