import React from "react";

export default function Unread({ unreadColor }) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1885 0H1.81148C0.811541 0.00106122 0.00111475 0.772571 0 1.72449V11.2755C0.00111475 12.2274 0.811541 12.9989 1.81148 13H15.1885C16.1885 12.9995 16.9994 12.2274 17 11.2755V1.72449C16.9989 0.772571 16.1885 0.00106122 15.1885 0ZM1.81148 1.32653H15.1885C15.3106 1.32706 15.4198 1.378 15.4945 1.45812L8.5 6.21506L1.50492 1.45865C1.58016 1.378 1.68997 1.32706 1.81148 1.32653ZM15.1885 11.6735H1.81148C1.58072 11.6735 1.39344 11.4952 1.39344 11.2755V3.01335L8.09479 7.57024C8.11207 7.5798 8.13325 7.58988 8.15498 7.5989L8.15944 7.60049C8.17672 7.61004 8.19734 7.62012 8.21908 7.62914L8.22354 7.63073C8.30436 7.66788 8.39911 7.69069 8.49889 7.69335H8.5H8.50111C8.602 7.69069 8.69675 7.66788 8.78148 7.62914L8.77702 7.63073C8.80266 7.62012 8.82384 7.61004 8.8439 7.5989L8.84056 7.60049C8.86675 7.58988 8.88793 7.57927 8.908 7.56812L8.90466 7.56971L15.606 3.01282V11.275C15.606 11.4947 15.4193 11.6735 15.1885 11.6735Z"
        fill={unreadColor}
      />
    </svg>
  );
}
