export default function DatePicker({ fromDate, setFromDate, toDate, setToDate, filterAction }) {

  return (
    <div className="date-picker a-center">

       <div className="fieldBox">
          <label htmlFor="from">Od:</label>
          <input type="date" id="from" value={fromDate} onChange={(e) => {setFromDate(e.target.value);}}/>
        </div>

        <div className="fieldBox">
          <label htmlFor="to">Do:</label>
          <input type="date" id="to" value={toDate} onChange={(e) => {setToDate(e.target.value);}}/>
        </div>

        <div className="fieldBox">
          <button className="btn-sm" onClick={() => filterAction()}>Filtruj</button>
        </div>

    </div>
  )
}
