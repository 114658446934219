
export default function Pagination({ setCurentPage, setPageSize, pagesCount }) {

  let pages = []
  for (let index = 1; index <= pagesCount; index++) {
    pages.push(index)
  }

  return (
    <div className="pagination">
      <div className="pagination-numbers">
        <p>Strona</p>
        <ul>
          {pages.map(page => (
          <li key={page} onClick={() => setCurentPage(page)}>{page}</li>
        ) )}
        </ul>
      </div>
      <div className="pagination-size">
        <p>Liczba zamówień na stronę</p>
        <ul>
          <li onClick={() => setPageSize(5)}>5</li>
          <li onClick={() => setPageSize(10)}>10</li>
          <li onClick={() => setPageSize(25)}>25</li>
          <li onClick={() => setPageSize(50)}>50</li>
        </ul>
      </div>
    </div>
  )
}
