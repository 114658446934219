import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import { useForm } from 'react-hook-form'
import Axios from "axios"

// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';

import FormInput from "../FormInput";

import { apiUrl } from "../../Vars";

export default function AdminDiscount() {

  const name = useParams().name

  const [ discountObject, setDiscountObject ] = useState()
  const [ isPennding, setIsPennding ] = useState(true)
  const [ err, setErr ] = useState()

  const [ idle, setIdle ] = useState(false)

  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createEmpty(),
  // );)

  useEffect(() => {
    if (name) {
      Axios.get(`${apiUrl}/api/discounts/${name}`, {
        headers: {
          "x-access-token" : `${sessionStorage.getItem("token")}`
        }, 
        withCredentials: true
      })
      .then(res => {
        const discountObject = {
          name: res.data.name,
          type: res.data.type,
          value: res.data.value,
          uses_count: res.data_uses_count,
          for_users: res.data_for_users,
          require_product: res.data_require_product,
          cart_value: res.data_cart_value,
          effect: res.data.effect,
          expire: res.data_expire
        }
        setDiscountObject(discountObject)
        setIsPennding(false);
        setErr(null);
      })
      .catch(err => {
        console.log(err)
        setIsPennding(false);
        setErr(err.message);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // edit form section --------------------------------------

  const shema = z.object({
    name: string().min(3, { message: "Pole jest wymagane" }),
    type: string().min(3, { message: "Pole jest wymagane" }),
    value: string().min(1, { message: "Pole jest wymagane" }),
    uses_count: string().optional(),
    for_users: string().optional(),
    require_product: string().optional(),
    cart_value: string().optional(),
    effect: string().min(3, { message: "Pole jest wymagane" }),
    expire: string().optional(),
  })

  const { register, handleSubmit, formState, reset } = useForm({ resolver: zodResolver(shema)});

  const { errors } = formState

  const discounttName = {name: 'name', label: 'Kod rabatowy', type: 'text'}
  const type = {name: 'type', label: 'Rodzaj zniżki', type: 'text'}
  const value = {name: 'value', label: 'Wartość zniżki', type: 'text'}
  const uses_count = {name: 'uses_count', label: 'Limit wykorzystania', type: 'text'}
  const for_users = {name: 'for_users', label: 'Dla kogo', type: 'text'}
  const require_product = {name: 'require_product', label: 'Wymagany produkt', type: 'text'}
  const cart_value = {name: 'cart_value', label: 'Minimalna wartość koszyka', type: 'text'}
  const effect = {name: 'effect', label: 'Działanie', type: 'text'}
  const expire = {name: 'expire', label: 'Wygasa', type: 'text'}

  useEffect(() => {
    if (name) {
      reset({...discountObject})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountObject])

  const submitDiscount = (data) => {
    console.log(data)
    const data_uses_count = data.uses_count === "" ? "unlimited" : data.uses_count
    const data_for_users = data.for_users === "" ? "unlimited" : data.for_users
    const data_require_product = data.require_product === "" ? "null" : data.require_product
    const data_cart_value = data.cart_value === "" ? "unlimited" : data.cart_value
    const data_expire = data.expire === "" ? "null" : data.expire
    const updatedObject = {
      name: data.name,
      type: data.type,
      value: data.value,
      uses_count: data_uses_count,
      for_users: data_for_users,
      require_product: data_require_product,
      cart_value: data_cart_value,
      effect: data.effect,
      expire: data_expire
    }
    
    Axios.post(`${apiUrl}/api/admin/discount-update/`, 
      {
        updatedObject
      }, {
      headers: {
        "x-access-token" : `${sessionStorage.getItem("token")}`
      }, 
      withCredentials: true
      })
      .then(res => {
        setIdle(true)
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // alert section ---------------------------------------------------

  useEffect(() => {
    if (idle === true) {
      const interval = setInterval(() => {
        setIdle(false)
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle]);

  // render section ------------------------------------------------------------

  return (
    <>
      {err ? <div>{err}</div> : <></>}
      {isPennding && name !== null ? <></> : 
        <div className="editProduct">
          <div className="editProduct__product">
          <form className="form" id="productForm" onSubmit={handleSubmit(submitDiscount)}>
            <div className="productForm">
              <div className="left flex g3 wrap">
                <div className="input_box">
                  <FormInput controls={discounttName} register={register}/>
                  <span className="input__err">{errors.name?.message}</span>
                  <p className="field-description">Podaj nazwę kodu rabatowego, tej samej nazwy klienci będą używać na stronie koszyka.</p>
                </div>
                <div className="input_box">
                  <FormInput controls={type} register={register}/>
                  <span className="input__err">{errors.type?.message}</span>
                  <p className="field-description">Określ jaki ma być rodzaj zniżki dla kodu. Dla wartości procentowej wpisz <span>percentage</span>, dla wartości kwotowej wpisz <span>value</span></p>
                </div>
                <div className="input_box">
                  <FormInput controls={value} register={register}/>
                  <span className="input__err">{errors.value?.message}</span>
                  <p className="field-description">Podaj wartośc liczbową znizki, np: 20</p>
                </div>
                <div className="input_box">
                  <FormInput controls={uses_count} register={register}/>
                  <span className="input__err">{errors.uses_count?.message}</span>
                  <p className="field-description">Podaj jaki ma być limit wykorzystania tego kodu przez pojedynczego użytkownika. Jeśli ma być nieograniczona, pozostaw pole puste.</p>
                </div>
                <div className="input_box">
                  <FormInput controls={for_users} register={register}/>
                  <span className="input__err">{errors.for_users?.message}</span>
                  <p className="field-description">Tutaj możesz określić, czy dany kod może być wykorzytsany przez każdego użytkownika, lub tylko wybranych. Jeśli każdy może skorzystać, pozostaw pole puste. W innym przypadku podaj adresy email użytkowików, którzy mogą skorzystać, oddzielone przecinkami.</p>
                </div>
                <div className="input_box">
                  <FormInput controls={require_product} register={register}/>
                  <span className="input__err">{errors.require_product?.message}</span>
                  <p className="field-description">Jeśli warunkiem skorzystania kodu ma być dodanie do koszyka kontretnego produktu, to tuataj podaj jego ID. Jeśli nie ma takiego ograniczenia, pozostaw pole puste.</p>
                </div>
                <div className="input_box">
                  <FormInput controls={cart_value} register={register}/>
                  <span className="input__err">{errors.cart_value?.message}</span>
                  <p className="field-description">Jeśli warunkiem uzycia kodu rabatowego jest minimalna wartość zamówienia, podaj ją tutaj, inaczej pozostaw pole puste.</p>
                </div>
                <div className="input_box">
                  <FormInput controls={effect} register={register}/>
                  <span className="input__err">{errors.effect?.message}</span>
                  <p className="field-description">Określ jak ma działać kod rabatowy. W przypadku obniżenia wartości całego koszyka wpisz <span>cart</span>. Jeśli kod ma tylko obniżyć wartość konretego produktu, podaj tutaj jego ID</p>
                </div>
                <div className="input_box">
                  <FormInput controls={expire} register={register}/>
                  <span className="input__err">{errors.expire?.message}</span>
                  <p className="field-description">Jeśli chcesz podać ważność kodu rabatowego, to wpisz tutaj datę wygaśnięcia w formacie <span>15.10.2023</span>, jeśli kod ma być aktywny cały czas, pozostaw pole puste.</p>
                </div>
              </div>
            </div>  
            {idle ? 
              <button className="btn idle marginTop">
                Kod został zaktualizowany
              </button> :
              <button className="btn marginTop" type="submit">Zaktualizuj kod</button>
            }
          </form>
          </div>
          <div className="marginTop"></div>
          <div className="editProduct__controlls">
            <NavLink to='/discounts'>
              <button className="btn marginTop" form="productForm">
                Wróć do listy kodów
              </button>
            </NavLink>
            <NavLink to='/discounts/add'>
              <button className="btn marginTop" form="productForm">
                Dodaj kod
              </button>
            </NavLink>
          </div>
        </div>
      }
    </>
  )
}
