import { NavLink } from "react-router-dom";

import { BsPersonCircle } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";

export default function Icons({ location }) {
  return (
    <div className={`icons ${location}`}>
      <NavLink to='/moje-konto'><BsPersonCircle /></NavLink>
      <NavLink to='/koszyk'><FaShoppingCart /></NavLink>
    </div>
  )
}
