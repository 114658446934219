export default function AdminReferralUserParamValue({ orders, param }) {

  console.log(orders, param)

  const paramOrders = orders.filter(order => order.refParam === param);

  let paramOrdersValue = 0

  paramOrders.forEach(order => {
    if (order.paymentStatus === 'COMPLETED') {
      paramOrdersValue = paramOrdersValue + order.orderValue
    } else {
      return null
    }
  });

  return (
    <span className="param-value secondary">
      {paramOrdersValue} zł
    </span>
  )
}
