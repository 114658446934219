import { NavLink } from "react-router-dom";
import { GetData } from "../../controllers/fetchData";
import { useEffect, useState } from "react";

export default function AdminReferralUsersTable({ users, isPennding, err }) {
  console.log(users);

  const { data, pennding, error } = GetData("admin/ref-users-data");

  const [referralData, setReferralData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  console.log(usersData);
  console.log(referralData);

  useEffect(() => {
    if (data && data.length !== 0) {
      setReferralData(data);
    }
  }, [data]);

  useEffect(() => {
    const usersTable = [];
    if (data && referralData.length !== 0 && users) {
      users.forEach((user) => {
        const userParams = referralData.params.filter((param) => param.referral_user_email === user.referral_user_email);
        const userClients = [];
        userParams.forEach((param) => {
          if (referralData.clients.filter((client) => client.referral_user_param === param.referral_user_param).length !== 0) {
            userClients.push(referralData.clients.filter((client) => client.referral_user_param === param.referral_user_param)[0].referral_client_email);
          }
        });
        const userOrders = [];
        userParams.forEach((param) => {
          const filteredOrders = referralData.orders.filter((order) => order.refParam === param.referral_user_param);
          userOrders.push(...filteredOrders);
        });
        let ordersValue = 0;
        userOrders.forEach((order) => {
          ordersValue = ordersValue + order.orderValue;
        });
        const u = {
          email: user.referral_user_email,
          name: user.referral_user_name,
          lastname: user.referral_user_lastname,
          params: userParams,
          clients: [...userClients],
          orders: [...userOrders],
          ordersValue: ordersValue,
        };
        usersTable.push(u);
      });
    }
    setUsersData(usersTable);
  }, [referralData, data, users]);

  return (
    <div className="adminOrders">
      <h3>Ambasadorzy</h3>
      <div className="adminOrders__row border-none hover-none">
        <div className="adminOrders__col w20 heading">Adres email</div>
        <div className="adminOrders__col w20 heading">Imię i nazwisko</div>
        <div className="adminOrders__col w20 heading">Ilość klientów</div>
        <div className="adminOrders__col w20 heading">Ilość zamówień</div>
        <div className="adminOrders__col w20 heading">Wynik sprzedaży</div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {usersData.length !== 0 && referralData.length !== 0 ? (
        <div className="adminOrders__table">
          {usersData.map((user) => {
            return (
              <NavLink
                to={`${user.email}`}
                key={user.email}
              >
                <div className="adminOrders__row">
                  <div className={`adminOrders__col w20`}>{user.email}</div>
                  <div className="adminOrders__col w20">
                    {" "}
                    {user.name} {user.lastname}{" "}
                  </div>
                  <div className="adminOrders__col w20">{user.clients.length}</div>
                  <div className="adminOrders__col w20">{user.orders.length + 1}</div>
                  <div className={`adminOrders__col w20`}>{user.ordersValue} zł</div>
                </div>
              </NavLink>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
