import { useState, useEffect } from "react"
import Axios from 'axios'

import AdminDiscountTable from "./AdminDiscountTable"
import Alert from "../Alert";

import { apiUrl } from "../../Vars"

export default function AdminDiscounts() {


  const token = sessionStorage.getItem("token")
  const uId = sessionStorage.getItem("userId")
  console.log(token)
  console.log(uId)

  const [ discounts, setDiscounts ] = useState([])
  const [ isPennding, setIsPennding ] = useState(true);
  const [ err, setErr ] = useState(null)

  const [ alertContent, setAlertContent ] = useState()
  const [ alertIsOn, setAlertIsOn ] = useState()

  useEffect(() => {
    Axios.get(`${apiUrl}/api/admin/discounts`, {
      headers: {
        "x-access-token" : `${sessionStorage.getItem("token")}`
      }, 
      withCredentials: true
    })
      .then(res => {
        setDiscounts(res.data);
        setIsPennding(false);
        setErr(null);
      })
      .catch(err => {
        console.log(err)
        setIsPennding(false);
        setErr(err.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(discounts)

  const showAlert = (alert, state) => {
    setAlertContent(alert)
    setAlertIsOn(state)
  }

  return (
    <>
    <Alert alert={alertContent} alertIsOn={alertIsOn} showAlert={showAlert}/>
    {discounts && <AdminDiscountTable 
      discounts={discounts}
      isPennding={isPennding}
      err={err} 
    />}
    </>
    
  )
}
