import { useEffect } from "react"

export default function Alert({ alert, alertIsOn, showAlert }) {

  useEffect(() => {
    const interval = setInterval(() => {
      showAlert('', false)
    }, 5000);
    return () => clearInterval(interval);
  }, [showAlert]);

  return (
    <>
      {alertIsOn ? 
        <div className="alert__box">
          <p>{alert}</p>
        </div>
      : <></>}
    </> 
  )
}
