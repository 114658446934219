import { useState, useEffect } from "react"
import Axios from 'axios'

import { apiUrl } from "../Vars"

import { filterAction, filterDateAction, filterTypeAction, filterPaymentAction, filterShippmentAction } from "../controllers/Filtering"

export const FilteredOrders = (filter, setFilteredOrders, setIsPennding, setErr) => {

  const [ orders, setOrders ] = useState([])
  const [ ordersByFilter, setOrdersByFilter ] = useState()
  const [ fromDate, setFromDate ] = useState()

  // console.log(filter)

  useEffect(() => {
    if (filter) {
      const date = new Date(filter.fromDate)
      const timestamp = date.getTime() - (3590*1000)
      setFromDate(timestamp)
    }
  }, [filter])

  useEffect(() => {
    if (fromDate) {
      Axios.get(`${apiUrl}/api/admin/orders/part/${fromDate}`, {
        headers: {
          "x-access-token" : `${sessionStorage.getItem("token")}`
        }, 
        withCredentials: true
      })
        .then(res => {
          setOrders(res.data);
          setIsPennding(false);
          setErr(null);
        })
        .catch(err => {
          console.log(err)
          setIsPennding(false);
          setErr(err.message);
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, fromDate])

  useEffect(() => {
    if (orders && filter) {
      const ordersByType = filterTypeAction(orders, filter.ordersType)
      // console.log('ordersByType')
      // console.log(ordersByType)
      const ordersByPayment = filterPaymentAction(ordersByType, filter.paymentSearch)
      // console.log('ordersByPayment')
      // console.log(ordersByPayment)
      const ordersByShippment = filterShippmentAction(ordersByPayment, filter.shippmentSearch)
      // console.log('ordersByShippment')
      // console.log(ordersByShippment)
      const ordersByDate = filterDateAction(ordersByShippment, filter.fromDate, filter.toDate)
      // console.log('ordersByDate')
      // console.log(ordersByDate)
      const ordersBySearch = filterAction(ordersByDate, filter.searchValue)
      // console.log('ordersBySearch')
      // console.log(ordersBySearch)
      setOrdersByFilter(ordersBySearch)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filter, orders])

  useEffect(() => {
    setFilteredOrders(ordersByFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersByFilter])

  return ordersByFilter
}