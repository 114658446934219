import { NavLink } from "react-router-dom";

export default function LoginMonit({ location }) {
  return (
    <div className="login-monit flex row j-center a-center g3 m-column">
      <div className="login-monit__form pl1 pr1">
        <h3 className="s3 primary">Nie jesteś zalogowany.</h3>
        <p className="s1_5 mb2">Zaloguj się, aby uzyskać dostęp to tej zawartości.</p>
        <NavLink to={`/login?location=${location}`}><button className="btn">Zaloguj się</button></NavLink>
      </div>
      <div className="login-monit__img">
        <img src="https://shop.genfactor.pl/assets/login-error.png" alt="zaloguj się" />
      </div>
    </div>
  )
}
