import React from "react";
import Unread from "./Icons/Unread";
import Ref from "./Icons/Ref";
import Discount from "./Icons/Discount";
import Invoice from "./Icons/Invoice";
import Type from "./Icons/Type";

export default function OrderIcons({ orderFlags }) {
  const partner30 = ["1021", "1030", "1032", "1033", "1034", "1037", "1038", "1039", "1040", "1042", "1043"];
  const unreadColor = orderFlags.isUnread ? "red" : "#DBDBDB";
  const refColor = orderFlags.isRef ? "green" : "#DBDBDB";
  const discountColor = orderFlags.isDiscount ? "orange" : "#DBDBDB";
  const invoiceColor = orderFlags.isInvoice ? "blue" : "#DBDBDB";
  let typeColor = "";
  switch (true) {
    case partner30.includes(orderFlags.id):
      typeColor = "DarkCyan";
      break;
    case orderFlags.orderType === "PRO":
      typeColor = "gold";
      break;
    default:
      typeColor = "DarkViolet";
      break;
  }

  return (
    <div className="order_icons">
      <div className="order_icon">
        <Unread unreadColor={unreadColor} />
      </div>
      <div className="order_icon">
        <Ref refColor={refColor} />
      </div>
      <div className="order_icon">
        <Type typeColor={typeColor} />
      </div>
      <div className="order_icon">
        <Discount discountColor={discountColor} />
      </div>
      <div className="order_icon">
        <Invoice invoiceColor={invoiceColor} />
      </div>
    </div>
  );
}
