import React from "react";

export default function Invoice({ invoiceColor }) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3487 0H2.54163V11.0089H11.3487V0ZM10.2478 9.90803H3.64247V1.10099H10.2478V9.90803Z"
        fill={invoiceColor}
      />
      <path
        d="M11.8991 8.25663V12.1097H1.99125V8.25663H0.890259V13.2107H13V8.25663H11.8991ZM6.94504 2.20184H4.19279V4.9541H6.94504V2.20184ZM5.8442 3.85311H5.29378V3.30269H5.8442V3.85311ZM7.49546 2.20184H9.6973V3.30269H7.49546V2.20184ZM7.49546 3.85311H9.6973V4.95395H7.49546V3.85311ZM4.19279 6.05494H9.69715V7.15578H4.19279V6.05494Z"
        fill={invoiceColor}
      />
      <path
        d="M4.19275 7.70621H9.69711V8.80705H4.19275V7.70621Z"
        fill={invoiceColor}
      />
    </svg>
  );
}
