import { useState, useEffect } from "react"
import Axios from 'axios'

import AdminUsersTable from "./AdminUsersTable"
import AdminUser from "./AdminUser"
import Alert from "../Alert";

import { apiUrl } from "../../Vars";

import { filterUsersAction } from "../../controllers/Filtering"

export default function AdminUsers() {

  const [ users, setUsers ] = useState([])
  const [ filteredUsers, setFilteredUsers ] =useState()
  const [ isPennding, setIsPennding ] = useState(true);
  const [ err, setErr ] = useState(null)
  const [ userDetails, setUserDetails ] = useState(false)
  const [ activeUser, setActiveUser ] = useState()

  const [ searchValue, setSearchValue ] = useState('')

  const [ alertContent, setAlertContent ] = useState()
  const [ alertIsOn, setAlertIsOn ] = useState()

  console.log(users)

  useEffect(() => {
    Axios.get(`${apiUrl}/api/admin/users`, {
      headers: {
        "x-access-token" : `${sessionStorage.getItem("token")}`
      }, 
      withCredentials: true
    })
      .then(res => {
        setUsers(res.data);
        setIsPennding(false);
        setErr(null);
      })
      .catch(err => {
        console.log(err)
        setIsPennding(false);
        setErr(err.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (users && users.length !== 0) {
      setFilteredUsers(filterUsersAction(users, searchValue))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, searchValue])

  const viewUser = (userId, state) => {
    setUserDetails(state)
    setActiveUser((users.filter(user => {return user.id === userId}))[0])
  }

  const showAlert = (alert, state) => {
    setAlertContent(alert)
    setAlertIsOn(state)
  }

  return (
    <>
    <Alert alert={alertContent} alertIsOn={alertIsOn} showAlert={showAlert}/>
    {userDetails ?
    (users.length !== 0 ? 
    <AdminUser user={activeUser} viewUser={viewUser} showAlert={showAlert}/> : <></>) :
    <AdminUsersTable 
      users={filteredUsers}
      isPennding={isPennding}
      err={err}
      viewUser={viewUser}
      setSearchValue={setSearchValue}
    /> }
    </>
    
  )
}
