import { NavLink } from "react-router-dom";

export default function AdminProductsTable({ products, isPennding, err }) {
  return (
    <div className="adminOrders">
      <h3>Produkty w sklepie</h3>
      <div className="adminOrders__row hover-none border-none">
        <div className="adminOrders__col prodName heading">Nazwa</div>
        <div className="adminOrders__col prodNetto heading">Cena Brutto</div>
        <div className="adminOrders__col prodNetto heading">
          Cena Brutto Partner 30
        </div>
        <div className="adminOrders__col prodNetto heading">
          Cena Brutto Partner 20
        </div>
        <div className="adminOrders__col prodNetto heading">
          Cena Brutto Gabinety
        </div>
        <div className="adminOrders__col prodNetto heading">Cena Funty</div>
        <div className="adminOrders__col prodQty heading">Ilość</div>
      </div>
      {isPennding ? <div>Wczytywanie danych</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {products ? (
        <div className="adminOrders__table">
          {products.map((product) => {
            return (
              <NavLink to={`${product.id}`} key={product.id}>
                <div className="adminOrders__row">
                  <div className="adminOrders__col prodName">
                    {product.name}
                  </div>
                  <div className="adminOrders__col prodNetto pl">
                    {product.brutto}
                  </div>
                  <div className="adminOrders__col prodNetto pl">
                    {product.brutto_a}
                  </div>
                  <div className="adminOrders__col prodNetto pl">
                    {product.brutto_b}
                  </div>
                  <div className="adminOrders__col prodNetto pl">
                    {product.brutto_c}
                  </div>
                  <div className="adminOrders__col prodNetto gb">
                    {product.brutto_d}
                  </div>
                  <div className="adminOrders__col prodQty">
                    {product.stock}
                  </div>
                </div>
              </NavLink>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <NavLink to="/products/add">
        <button className="btn marginTop" form="productForm">
          Dodaj produkt
        </button>
      </NavLink>
    </div>
  );
}
