import React from 'react'
import AdminDashboardValue from './AdminDashboardValue'
import AdminDashboardOrders from "./AdminDashboardOrders";

export default function AdminDashboardModule({ sortedOrders }) {
  return (
    <div>
      <AdminDashboardValue orders={sortedOrders}/>
      <AdminDashboardOrders orders={sortedOrders} />
    </div>
  )
}
