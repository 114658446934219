import { NavLink } from "react-router-dom";

export default function AdminMenu({ toggleModule, activeModule }) {
  return (
    <div className="adminMenu">
      <ul className="flex flex-row flex-wrap">
        <NavLink to="orders">
          <li
            className={`adminMenu__item ${activeModule === "orders" ? "active" : ""}`}
            onClick={() => toggleModule("orders")}
          >
            Zamówienia
          </li>
        </NavLink>
        <NavLink to="products">
          <li
            className={`adminMenu__item ${activeModule === "products" ? "active" : ""}`}
            onClick={() => toggleModule("products")}
          >
            Produkty
          </li>
        </NavLink>
        <NavLink to="users">
          <li
            className={`adminMenu__item ${activeModule === "clients" ? "active" : ""}`}
            onClick={() => toggleModule("clients")}
          >
            Klienci
          </li>
        </NavLink>
        <NavLink to="discounts">
          <li
            className={`adminMenu__item ${activeModule === "discounts" ? "active" : ""}`}
            onClick={() => toggleModule("discounts")}
          >
            Kody rabatowe
          </li>
        </NavLink>
        <NavLink to="ref-users">
          <li
            className={`adminMenu__item ${activeModule === "ref-users" ? "active" : ""}`}
            onClick={() => toggleModule("ref-users")}
          >
            Ambasadorzy
          </li>
        </NavLink>
      </ul>
    </div>
  );
}
