import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import AdminReferralUsersTable from "./AdminReferralUsersTable";
import Alert from "../Alert";
import { GetData } from "../../controllers/fetchData";

export default function AdminReferralUsers() {
  const { data, pennding, error } = GetData("admin/ref-users");

  const [users, setUsers] = useState([]);
  const [isPennding, setIsPennding] = useState(true);
  const [err, setErr] = useState(null);
  const [alertIsOn, setAlertIsOn] = useState(false);
  const [alertContent, setAlertContent] = useState(null);

  useEffect(() => {
    setUsers(data);
    setIsPennding(pennding);
    setErr(error);
  }, [data, pennding, error]);

  const showAlert = (alert, state) => {
    setAlertContent(alert);
    setAlertIsOn(state);
  };

  return (
    <>
      <Alert alert={alertContent} alertIsOn={alertIsOn} showAlert={showAlert} />
      {/* {userDetails ?
    (users.length !== 0 ? 
    <AdminUser user={activeUser} viewUser={viewUser} showAlert={showAlert}/> : <></>) : */}
      <AdminReferralUsersTable
        users={users}
        isPennding={isPennding}
        err={err}
        // viewUser={viewUser}
        // setSearchValue={setSearchValue}
      />
      <NavLink to="/ref-users/add">
        <button className="btn-sm marginTop">Dodaj Ambasadora</button>
      </NavLink>
    </>
  );
}
