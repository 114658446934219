import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { zodResolver } from '@hookform/resolvers/zod'
import { string, z } from 'zod'
import { useForm } from 'react-hook-form'

import { usePostData } from "../../controllers/fetchData";

import FormInput from "../FormInput";

export default function AdminReferralUserAdd() {

  const {response, idle, error, makePostRequest } = usePostData()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ msg, setMsg ] = useState()
  // const [ error, setError ] = useState(null)

  console.log(response)
  console.log(idle)
  console.log(error)

  // add form section --------------------------------------

  const shema = z.object({
    referral_user_email: string().min(1, { message: "Pole jest wymagane" }),
    referral_user_name: string().min(1, { message: "Pole jest wymagane" }),
    referral_user_lastname: string().min(1, { message: "Pole jest wymagane" }),
  })

  const { register, handleSubmit, formState } = useForm({ resolver: zodResolver(shema)});

  const { errors } = formState

  const referral_user_email = {name: 'referral_user_email', label: 'Adres email', type: 'text'}
  const referral_user_name = {name: 'referral_user_name', label: 'Imię', type: 'text'}
  const referral_user_lastname = {name: 'referral_user_lastname', label: 'Nazwisko', type: 'text'}

  // submit new user ----------------------------------------------------

  const submitReferralUser = (data) => {
    console.log(data)
    const referralUser = {
      referral_user_email: data.referral_user_email,
      referral_user_name: data.referral_user_name,
      referral_user_lastname: data.referral_user_lastname
    }
    makePostRequest('admin/add-ref-user', referralUser)
  }

  useEffect(() => {
    if(response) {
      setMsg(response.data)
      setIsLoading(true)
    }
    setIsLoading(false)
  }, [response])
  
  // alert section ---------------------------------------------------

  useEffect(() => {
    if (isLoading === true) {
      const interval = setInterval(() => {
        setIsLoading(false)
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle, isLoading]);

  // render section ------------------------------------------------------------

  return (
    <>
      {msg && <div className="mb-8">{msg}</div>}
      <div className="editRefUser">
        <div className="editRefUser__refUser">
          <form className="form" id="refUserForm" onSubmit={handleSubmit(submitReferralUser)}>
            <div className="refUserForm">
              <div className="editRefUser__field">
                <FormInput controls={referral_user_email} register={register}/>
                <span className="input__err">{errors.referral_user_email?.message}</span>
              </div>
              <div className="editRefUser__field">
                <FormInput controls={referral_user_name} register={register}/>
                <span className="input__err">{errors.referral_user_name?.message}</span>
              </div>
              <div className="editRefUser__field">
                <FormInput controls={referral_user_lastname} register={register}/>
                <span className="input__err">{errors.referral_user_lastname?.message}</span>
              </div>
              <div className="editRefUser__field">
                {isLoading ? 
                  <button className="btn idle mb0 h100">
                    Ambasador został dodany
                  </button> :
                  <button className="btn mb0 h100" type="submit">Dodaj Ambasadora</button>
                }
              </div>
            </div>
          </form>
        </div>
        <div className="marginTop"></div>
        <NavLink to='/ref-users'>
          <button className="btn marginTop" form="productForm">
            Wróć do listy Ambasadorów
          </button>
        </NavLink>
      </div>
    </>
  )
}
