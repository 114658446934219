import { useState, useEffect } from "react";

export default function AdminDashboardValue({ orders }) {
  const [ordersValue, setOrdersValue] = useState();
  const [payedOrdersValue, setPayedOrdersValue] = useState();
  const [pendingOrdersValue, setPendingOrdersValue] = useState();
  const [newOrdersValue, setNewOrdersValue] = useState();

  useEffect(() => {
    let payedOrders;
    let pendingOrders;
    let newOrders;
    let valueComplete = 0;
    let valuePending = 0;
    let newValue = 0;
    if (orders) {
      payedOrders = orders.filter((order) => {
        return order.paymentStatus === "COMPLETED";
      });
      pendingOrders = orders.filter((order) => {
        return order.paymentStatus === "PENDING";
      });
      payedOrders.forEach((order) => {
        valueComplete = valueComplete + order.orderValue;
      });
      pendingOrders.forEach((order) => {
        valuePending = valuePending + order.orderValue;
      });
      newOrders = orders.filter((order) => {
        return order.unread === "true";
      });
      newOrders.forEach((order) => {
        newValue = newValue + order.orderValue;
      });
    }
    setPayedOrdersValue(valueComplete);
    setPendingOrdersValue(valuePending);
    setOrdersValue(payedOrdersValue + pendingOrdersValue);
    setNewOrdersValue(Math.round(newValue * 100) / 100);
  }, [orders, payedOrdersValue, pendingOrdersValue]);

  console.log(orders, payedOrdersValue, pendingOrdersValue, ordersValue);

  const headingStyle = "mt-1 mb-0";
  const valueSingleBlockStyle = "flex flex-col justify-between";

  return (
    <>
      {orders.length !== 0 && ordersValue ? (
        <>
          <h3 className="mt-8 mb-0 sm:text-2xl sm:mb-2">Wartość zamówień</h3>
          <div className="flex gap-8 flex-col sm:flex-row sm:flex-wrap">
            <div className={valueSingleBlockStyle}>
              <h4 className="mt-0 mb-0">Wartość wszystkich zamówień</h4>
              <h2 className={`${headingStyle} text-tl-primary`}>{ordersValue.toFixed(2)} zł</h2>
            </div>
            <div className={valueSingleBlockStyle}>
              <h4 className="mt-0 mb-0">Wartość opłaconych zamówień</h4>
              <h2 className={`${headingStyle} text-green-700`}>{payedOrdersValue.toFixed(2)} zł</h2>
            </div>
            <div className={valueSingleBlockStyle}>
              <h4 className="mt-0 mb-0">Wartość nieopłaconych zamówień</h4>
              <h2 className={`${headingStyle} text-orange-500`}>{pendingOrdersValue.toFixed(2)} zł</h2>
            </div>
            <div className={valueSingleBlockStyle}>
              <h4 className="mt-0 mb-0">Wartość nowych zamówień</h4>
              <h2 className={`${headingStyle} text-blue-700`}>{newOrdersValue} zł</h2>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
