import React from "react";

export default function Ref({ refColor }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.15485 6.8575L6.85709 9.15689C6.75959 9.25439 6.62634 9.28283 6.5 9.25845C6.37366 9.28283 6.24041 9.25439 6.14291 9.15689L3.84515 6.8575C3.68631 6.69906 3.68631 6.44311 3.84515 6.28061C4.00359 6.12217 4.26075 6.12217 4.41959 6.28061L6.09375 7.95842V3.65625C6.09375 3.43281 6.27575 3.25 6.5 3.25C6.72466 3.25 6.90625 3.43281 6.90625 3.65625V7.95842L8.58041 6.28061C8.73925 6.12217 8.99681 6.12217 9.15485 6.28061C9.31369 6.44311 9.31369 6.69906 9.15485 6.8575ZM6.5 0C2.90997 0 0 2.90875 0 6.5C0 10.0913 2.90997 13 6.5 13C10.09 13 13 10.0913 13 6.5C13 2.90875 10.09 0 6.5 0Z"
        fill={refColor}
      />
    </svg>
  );
}
