import { NavLink } from "react-router-dom"

export default function Navigation({ location }) {
	return(
		<div className={`navigation ${location}`}>
      <ul className="navigation__list">
        <li key='1'><NavLink to='/'>Produkty</NavLink></li>
        <li key='2'><NavLink to='/moje-konto'>Moje Konto</NavLink></li>
        <li key='3'><NavLink to='/shop'>Kontakt</NavLink></li>
        <li key='4'><NavLink to='/login'>Zaloguj się</NavLink></li>
		  </ul>
    </div>
	)
}