import { useState } from "react";
import { Outlet } from "react-router";

import { useAuth } from "../../controllers/useAuth";
// import { useUser } from "../../controllers/UserContext";

// import { gusApi } from '../../controllers/ApiValidation';
// import { nipValidation } from '../../controllers/ApiValidation';

import AdminMenu from "../../components/Admin/AdminMenu";
import LoginMonit from "../../components/Layout/LoginMonit";

export default function AdminArea() {
  // const [ result, setResult ] = useState()

  const userLoggedIn = useAuth();

  // const userObject = useUser()

  // const validate = (data) => {
  //   const result = nipValidation(data)
  //   setResult(result)
  // }

  // console.log(result)

  const [activeModule, setActiveModule] = useState("dashboard");

  const toggleModule = (module) => {
    setActiveModule(module);
  };

  return (
    <>
      {!userLoggedIn ? (
        <LoginMonit location={"administration-area/dashboard"} />
      ) : (
        <div className="adminArea">
          <div className="adminArea__main">
            <div></div>
            <AdminMenu
              toggleModule={toggleModule}
              activeModule={activeModule}
            />
            <div className="adminArea__module">
              <Outlet />
              {/* <button onClick={() => validate("5381725210")}>Sprawdź GUS</button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
