// import Icons from "./Icons";
import Logo from "./Logo";
// import Navigation from "./Navigation";
// import LanguageSwitcher from "../LanguageSwitcher";

// import { FaBars } from "react-icons/fa";

export default function Header({ handleModal }) {
  return (
    <>
      <div className="header_box">
        <div className="flex flex-col items-center sm:flex-row sm:gap-2 sm:justify-around">
          <Logo />
          <h4 className="text-2xl text-white mb-2">Panel Administratora</h4>
          {/* <div className="header">
            <Navigation location={'desktop'}/>
            <Icons location={'desktop'}/>
            <div onClick={() => handleModal()} className="mobile"><FaBars /></div>
            <LanguageSwitcher />
          </div> */}
        </div>
      </div>
    </>
  );
}
