import React from "react";

export default function Type({ typeColor }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49997 13C10.0495 13 13 10.0559 13 6.5C13 2.95049 10.0431 0 6.49361 0C2.93772 0 0 2.95049 0 6.5C0 10.0559 2.9441 13 6.49997 13ZM6.49997 8.67304C4.58184 8.67304 3.10341 9.3549 2.45978 10.1196C1.60587 9.1637 1.08967 7.89558 1.08967 6.5C1.08967 3.49853 3.48577 1.08334 6.49361 1.08334C9.49506 1.08334 11.9102 3.49853 11.9167 6.5C11.9167 7.90197 11.4005 9.17009 10.5402 10.126C9.89654 9.36126 8.41811 8.67304 6.49997 8.67304ZM6.49997 7.5897C7.7235 7.60245 8.68576 6.55734 8.68576 5.18726C8.68576 3.90001 7.71714 2.82942 6.49997 2.82942C5.27645 2.82942 4.30146 3.90001 4.31421 5.18726C4.32057 6.55734 5.27008 7.57695 6.49997 7.5897Z"
        fill={typeColor}
      />
    </svg>
  );
}
