import { useState, useEffect } from "react"
import Axios from 'axios'

import AdminProductsTable from "./AdminProductsTable"

import { apiUrl } from "../../Vars"

export default function AdminProducts() {

  const [ products, setProducts ] = useState([])
  const [ isPennding, setIsPennding ] = useState(true);
  const [ err, setErr ] = useState(null)

  useEffect(() => {
    Axios.get(`${apiUrl}/api/products`)
      .then(res => {
        setProducts(res.data);
        setIsPennding(false);
        setErr(null);
      })
      .catch(err => {
        console.log(err)
        setIsPennding(false);
        setErr(err.message);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AdminProductsTable 
        products={products}
        isPennding={isPennding}
        err={err}
      /> 
    </>
  )
}
