//basic
import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { UserProvider } from './controllers/UserContext';
import { CartProvider } from './controllers/Cart';
import { AuthProvider } from './controllers/useAuth.jsx';
import { LanguageProvider } from './controllers/Language';
// import { useAuth } from './controllers/useAuth.jsx';

import './App.css';
import './index.css'

import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import AdminArea from './routes/admin/AdminArea';
import AdminOrders from './components/Admin/AdminOrders';
import AdminDashboard from './components/Admin/AdminDashboard';
import AdminProducts from './components/Admin/AdminProducts';
import AdminUsers from './components/Admin/AdminUsers';
import AdminOrder from './components/Admin/AdminOrder';
import AdminProduct from './components/Admin/AdminProduct';
import AdminProductAdd from './components/Admin/AdminProductAdd';
import AdminUser from './components/Admin/AdminUser';
import Modal from './components/Layout/Modal';
import MobileMenu from './components/Layout/MobileMenu';
import ScrollToTop from './components/Layout/ScrollToTop';
import Skeletons from './routes/static/Skeletons';
import AuthInfo from './components/Layout/AuthInfo';
import Logowanie from './routes/Logowanie';
import AdminDiscounts from './components/Admin/AdminDiscounts';
import AdminDiscount from './components/Admin/AdminDiscount';
import AdminDiscountAdd from './components/Admin/AdminDiscountAdd';
import AdminReferralUsers from './components/Admin/AdminReferralUsers.jsx';
import AdminReferralUserAdd from './components/Admin/AdminReferralUserAdd.jsx';
import AdminReferralUser from './components/Admin/AdminReferralUser.jsx';

function App() {

  const [isModal, setIsModal] = useState(false)

  // const authUser = useAuth()

  const handleModal = () => {
    setIsModal(prevIsModal => !prevIsModal)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <LanguageProvider>
          <UserProvider>
            <AuthProvider>
              <CartProvider>
                <AuthInfo />
                <Header handleModal={handleModal} />
                <Modal isModal={isModal} handleModal={handleModal}>
                  <MobileMenu handleModal={handleModal} />
                </Modal>
                <div className='main'>
                  <ScrollToTop>
                    <Routes>
                      <Route path='/' element={<AdminArea />}>
                        <Route path='dashboard' element={<AdminDashboard />} />
                        <Route path='orders' element={<AdminOrders />} />
                        <Route path='orders/:id' element={<AdminOrder />} />
                        <Route path='products' element={<AdminProducts />} />
                        <Route path='products/:id' element={<AdminProduct />} />
                        <Route path='products/add' element={<AdminProductAdd />} />
                        <Route path='users' element={<AdminUsers />} />
                        <Route path='users/:id' element={<AdminUser />} />
                        <Route path='discounts' element={<AdminDiscounts />} />
                        <Route path='discounts/:name' element={<AdminDiscount />} />
                        <Route path='discounts/add' element={<AdminDiscountAdd />} />
                        <Route path='ref-users' element={<AdminReferralUsers />} />
                        <Route path='ref-users/add' element={<AdminReferralUserAdd />} />
                        <Route path='ref-users/:email' element={<AdminReferralUser />} />
                      </Route>
                      <Route path='/login' element={<Logowanie />} />
                      <Route path='/skeletons' element={<Skeletons />} />
                    </Routes>
                  </ScrollToTop>
                </div>
                <Footer />
              </CartProvider>
            </AuthProvider>
          </UserProvider>
        </LanguageProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
