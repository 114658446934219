import { useState, useEffect } from "react";

export default function AdminDashboardOrders({ orders }) {
  const [newOrders, setNewOrders] = useState();
  const [ordersStatus, setOrdersStatus] = useState([]);

  useEffect(() => {
    let o;
    if (orders && orders.length !== 0) {
      o = orders.filter((order) => {
        return order.unread !== "true";
      });
    }
    setNewOrders(o);
  }, [orders]);

  console.log(orders);

  useEffect(() => {
    let complete = [];
    let pending = [];
    let finished = [];
    let prep = [];
    let shipPending = [];
    let canceled = [];
    let orderStatus = [];
    if (orders && orders.length !== 0) {
      complete = orders.filter((order) => {
        return order.paymentStatus === "COMPLETED";
      });
      pending = orders.filter((order) => {
        return order.paymentStatus === "PENDING";
      });
      finished = orders.filter((order) => {
        return order.shippmentStatus === "FINISHED";
      });
      prep = orders.filter((order) => {
        return order.shippmentStatus === "PREP";
      });
      shipPending = orders.filter((order) => {
        return order.shippmentStatus === "PENDING";
      });
      canceled = orders.filter((order) => {
        return order.paymentStatus === "CANCELED";
      });
      orderStatus.push({ status: "Opłacone", count: complete.length });
      orderStatus.push({ status: "Oczekujące", count: pending.length });
      orderStatus.push({ status: "Zakończone", count: finished.length });
      orderStatus.push({ status: "Przygotowane", count: prep.length });
      orderStatus.push({ status: "Do wysłania", count: shipPending.length });
      orderStatus.push({ status: "Anulowane", count: canceled.length });
    }
    setOrdersStatus(orderStatus);
  }, [newOrders, orders]);

  console.log(ordersStatus);

  return (
    <>
      {ordersStatus && ordersStatus.length !== 0 ? (
        <>
          <div className="adminDashboard__orders mt-8">
            <div className="adminDashboard__status">
              <h3>Liczba zamówień według statusu</h3>
              <div className="flex flex-col gap-2 font-bold sm:flex-row sm:flex-wrap sm:gap-8">
                <div className="adminDashboard__statusInner">
                  <p className="text-green-500 ">
                    Opłacone: <span>{ordersStatus[0].count}</span>
                  </p>
                </div>
                <div className="adminDashboard__statusInner">
                  <p className="text-yellow-500">
                    Do opłacenia: <span>{ordersStatus[1].count}</span>
                  </p>
                </div>
                <div className="adminDashboard__statusInner">
                  <p className="text-blue-500">
                    Wysłane: <span>{ordersStatus[2].count}</span>
                  </p>
                </div>
                <div className="adminDashboard__statusInner">
                  <p className="text-cyan-400">
                    Do wysłania: <span>{ordersStatus[3].count}</span>
                  </p>
                </div>
                <div className="adminDashboard__statusInner">
                  <p className="text-orange-500">
                    Do przygotowania: <span>{ordersStatus[4].count}</span>
                  </p>
                </div>
                <div className="adminDashboard__statusInner">
                  <p className="text-red-500">
                    Anulowane: <span>{ordersStatus[5].count}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
