import { useState } from "react"
import Axios from "axios"

import { apiUrl } from "../Vars"

export default function SetPassword({ userId, email, setAlertContent, setAlertIsOn }) {

  const [ newPassword, setNewPassword ] = useState()

  console.log(newPassword)

  const savePassword = (e) => {
    e.preventDefault()
    Axios.post(`${apiUrl}/api/admin/userupdate/${userId}`, 
      {
        newPassword: newPassword,
        email: email
      }, {
      headers: {
        "x-access-token" : `${sessionStorage.getItem("token")}`
      }, 
      withCredentials: true
      })
      .then(res => {
        setAlertContent('Zaktualizowano hasło')
      setAlertIsOn(true)
      console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="set-password mt1">
      <form action="/" className="set-password-form">
        <label htmlFor="newPassword">Nowe hasło:</label>
        <input type="text" id="newPassword" name="newPassword" onChange={(e) => {setNewPassword(e.target.value)}}/>
        <button className="btn btn-sm" type="submit" onClick={(e) => savePassword(e)}>Ustaw nowe hasło</button>
      </form>
    </div>
  )
}
