import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router";

import { GetData, usePostData } from "../../controllers/fetchData";
import DatePicker from "../DatePicker";

import { filterDateAction } from "../../controllers/Filtering.jsx"
import AdminReferralUserParamValue from "./AdminReferralUserParamValue.jsx";

export default function AdminReferralUser() {
  const referral_user_email = useParams().email;

  const { data, pennding, error } = GetData(`admin/ref-users/${referral_user_email}`);

  const { response, idle, postError, makePostRequest } = usePostData();

  const [ user, setUser ] = useState();
  const [ isPennding, setIsPennding ] = useState(true);
  const [ err, setErr ] = useState(null);
  const [ paramName, setParamName ] = useState();
  const [ ordersValue, setOrdersValue ] = useState();
  const [ fromDate, setFromDate ] = useState('')
  const [ toDate, setToDate ] = useState('')
  const [ orders, setOrders]  = useState([])

  useEffect(() => {
    setUser(data);
    if (data) {
      setOrders(data.referral_user_orders)
    }
    setIsPennding(pennding);
    setErr(error);
  }, [data, pennding, error]);

  const addParam = () => {
    const newParam = {
      referral_user_email: user.referral_user_email,
      referral_user_param_name: paramName,
    };
    makePostRequest("admin/ref-users/add-param", newParam);
  };

  const disableParam = (param) => {
    const disableParam = {referral_user_param: param}
    makePostRequest('admin/ref-user/disable-param', disableParam)
  }

  useEffect(() => {
    let value = 0;
    orders.forEach((order) => {
      if (order.paymentStatus === 'COMPLETED')
      value = value + order.orderValue;
    });
    setOrdersValue(value);
  }, [orders]);

  console.log(user);

  const filterAction = () => {
    if (data && orders) {
      const ordersByDate = filterDateAction(data.referral_user_orders, fromDate, toDate)
      setOrders(ordersByDate)
    }
  }

  return (
    <div className="ref-user">
      {isPennding ? <div>Ładowanie...</div> : <></>}
      {err ? <div>{err}</div> : <></>}
      {user ? (
        <div>
          <p>Dane Ambasadora</p>
          <div className="ref-user__data">
            <p>Email:</p>
            <NavLink to={`/users/${user.id}`} >
            <h3 className="mr1">{user.referral_user_email}</h3>
            </NavLink>
            <p>Imię i Nazwisko:</p>
            <h3 className="mr1">{user.referral_user_name} {user.referral_user_lastname}</h3>
            <p>Data rejestracji:</p>
            <p>{new Date(user.timestamp).toLocaleDateString("ISO", {day: "numeric", month: "short", year: "numeric"})}</p>
          </div>
          <div className="ref-user__data mt2 a-center">
            <div>Wybierz zakres dat:</div>
            <DatePicker fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} filterAction={filterAction}/>
          </div>
          <div className="ref-user__data mt2">
            <p className="mr1">Wartość zamówień:</p>
            <h3>{ordersValue} zł</h3>
          </div>
          <div className="mt2">
            <p>Linki referencyjne do zapisu klientów:</p>
            {user.referral_user_params.map((param) => (
              <div className="mb-10" key={param.referral_user_param_name}>
                <h3 className="text-tl-primary mb-0">{param.referral_user_param_name}:</h3>
                <h3 className="mt-0 mb-0">https://shop.genfactor.pl/rejestracja?ref=true&param={param.referral_user_param}</h3>
                <p className="mb-0">Wartość zamówień dla tego parametru: <AdminReferralUserParamValue orders={orders} param={param.referral_user_param} /></p>
                {param.referral_user_param_state === 'active' ?
                <div onClick={() => disableParam(param.referral_user_param)} className="text-tl-primary font-bold hover:cursor-pointer">Wyłącz parametr</div>
                : <div className="text-tl-accent font-bold">Parametr wyłączony</div>}
              </div>
            ))}
          </div>
          <hr />
          <div className="flex g2 a-center mb2 mt3">
            <p className="mb0">Nazwa parametru: </p>
            <input type="text" onChange={(e) => setParamName(e.target.value)} className="mr2 pt1 pr1 pl1 pb1" />
            <button className="btn-sm" onClick={() => addParam()} disabled={idle}>Dodaj Parametr</button>
          </div>
          {response ? <p>{response.data}</p> : <></>}
          
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
