import { useState, useEffect } from "react";

export default function FilteringModule({ setFilter }) {
  const timestamp = Date.now();
  const date = new Date(timestamp - 1000 * 60 * 60 * 24 * 14);
  // console.log(date)
  const now = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;

  // console.log(now)

  const [filterState, setFilterState] = useState(JSON.parse(localStorage.getItem("filter")));
  const [searchValue, setSearchValue] = useState(filterState ? filterState.searchValue : "");
  const [fromDate, setFromDate] = useState(filterState ? filterState.fromDate : now);
  const [toDate, setToDate] = useState(filterState ? filterState.toDate : "");
  const [paymentSearch, setPaymentSearch] = useState(filterState ? filterState.paymentSearch : "");
  const [shippmentSearch, setShippmentSearch] = useState(filterState ? filterState.shippmentSearch : "");
  const [ordersType, setOrdersType] = useState(filterState ? filterState.ordersType : "all");

  // console.log(filterState)
  // console.log(filterState)

  const clearFilter = () => {
    setSearchValue("");
    setFromDate(now);
    setToDate("");
    setPaymentSearch("");
    setShippmentSearch("");
    setOrdersType("all");
    const filterObject = {
      fromDate: now,
      toDate: "",
      searchValue: "",
      paymentSearch: "",
      shippmentSearch: "",
      ordersType: "all",
    };
    localStorage.setItem("filter", JSON.stringify(filterObject));
    setFilterState(filterObject);
    setFilter(filterObject);
  };

  const setFiltering = () => {
    const filterObject = {
      fromDate: fromDate,
      toDate: toDate,
      searchValue: searchValue,
      paymentSearch: paymentSearch,
      shippmentSearch: shippmentSearch,
      ordersType: ordersType,
    };
    localStorage.setItem("filter", JSON.stringify(filterObject));
    setFilterState(filterObject);
    setFilter(filterObject);
  };

  useEffect(() => {
    const filterObject = {
      fromDate: fromDate,
      toDate: toDate,
      searchValue: searchValue,
      paymentSearch: paymentSearch,
      shippmentSearch: shippmentSearch,
      ordersType: ordersType,
    };
    if (!filterState) {
      localStorage.setItem("filter", JSON.stringify(filterObject));
    }
    setFilter(filterObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="adminOrders adminOrders__searchbar border-none a-end hover-none">
        <div className="fieldBox">
          <label htmlFor="typ">Typ:</label>
          <select
            type="select"
            id="typ"
            value={ordersType}
            onChange={(e) => {
              setOrdersType(e.target.value);
            }}
          >
            <option value="all">Wszystkie</option>
            <option value="PRO">Profesjonale</option>
            <option value="DETAL">Detaliczne</option>
          </select>
        </div>

        <div className="fieldBox">
          <label htmlFor="search">Szukaj:</label>
          <input
            type="text"
            id="search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>

        <div className="fieldBox">
          <label htmlFor="from">Od:</label>
          <input
            type="date"
            id="from"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>

        <div className="fieldBox">
          <label htmlFor="to">Do:</label>
          <input
            type="date"
            id="to"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </div>

        <div className="fieldBox">
          <label htmlFor="status">Płatność:</label>
          <select
            type="select"
            id="status"
            value={paymentSearch}
            onChange={(e) => {
              setPaymentSearch(e.target.value);
            }}
          >
            <option value="">Wszystkie</option>
            <option value="COMPLETED">Opłacone</option>
            <option value="PENDING">Oczekujące</option>
            <option value="CANCELED">Anulowane</option>
          </select>
        </div>

        <div className="fieldBox">
          <label htmlFor="status">Wysyłka:</label>
          <select
            type="select"
            id="status"
            value={shippmentSearch}
            onChange={(e) => {
              setShippmentSearch(e.target.value);
            }}
          >
            <option value="">Wszystkie</option>
            <option value="PENDING">Oczekujące</option>
            <option value="PREP">Przygotowane</option>
            <option value="IN_TRANSIT">Wysłane</option>
            <option value="ON_DELIVERY">W doręczeniu</option>
            <option value="FINISHED">Dostarczone</option>
            <option value="CANCELED">Anulowane</option>
          </select>
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-3 justify-center items-center sm:flex-row sm:justify-start">
          <button
            className="btn w-100 py-2 sm:w-48"
            onClick={() => {
              setFiltering();
            }}
          >
            Filtruj
          </button>
          <button
            className="btn w-100 py-2 sm:w-48"
            onClick={() => {
              clearFilter();
            }}
          >
            Wyczyść
          </button>
        </div>
      </div>
    </div>
  );
}
