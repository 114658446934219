import { useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from 'react-router-dom'
import { NavLink } from "react-router-dom"
import Axios from "axios"

import { useDispatchUser } from '../controllers/UserContext'
import { apiUrl } from "../Vars"


export default function LoginForm({location, action}) {

  const [ useremail, setUseremail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ err, setErr ] = useState()
  const dispatch = useDispatchUser()

  const navigate = useNavigate()
  const [ params ] = useSearchParams()

  const registerStatus = (params.get('msg'))

  const login = (e) => {
    e.preventDefault()
    Axios.post(`${apiUrl}/api/admin/login`, {
      email: useremail,
      password: password
    }, {
      baseURL: apiUrl,
      withCredentials: true
    }).then((response) => {
      const authObject = {
        auth: true,
        user: response.data.user,
        token: response.data.token
      }
      dispatch({ type: "CREATE", authObject })
      if (location === null) {
        navigate(`/orders`)
      } else {
        navigate(`/orders`)
      }
    }).catch((err) => {
      console.log(err.response.data)
      setErr(err.response.data.err)
    })
  };

  return (
    <div className={location}>
      <form action="/" className="form">
        <div className="login__box">
          {registerStatus === 'register-ok' && <p className="msg green">Zarejestrowałeś się poprawnie, możesz się zalogować.</p>}
          {registerStatus === 'reset-ok' && <p className="msg green">Ustawiłeś nowe hasło, możesz się zalogować.</p>}
          {err && <p className="msg red">{err}</p>}
          <div>
            <label htmlFor="email">Twój email</label>
            <input type="text" id="email" name="email" onChange={(e) => {setUseremail(e.target.value)}}/>
          </div>
          <div>
            <label htmlFor="password">Twoje hasło</label>
            <input type="password" id="password" name="password" onChange={(e) => {setPassword(e.target.value)}}/>
          </div>
        </div>
        <button type="submit" className="btn" onClick={(e) => {login(e)}}>Zaloguj się</button>
      </form>
      <p>Nie pamiętasz hasła? Zresetuj je <strong><NavLink to='/reset'>tutaj</NavLink></strong>.</p>
    </div>
  )
}
